/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/href-no-hash */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import env from "@mars/heroku-js-runtime-env";
import { CandidateReportStyles as styles } from "../../../styles";
import Loader from "../../../components/Loader";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

import ReportDate from "../../../components/BackgroundReport/ReportDate";
import ApplicantInfo from "../../../components/BackgroundReport/ApplicantInfo";
import ReportChecks from "../../../components/BackgroundReport/Checks";
import ReportsProcessing from "../../../components/BackgroundReport/reports-processing";
import messages from "../../../components/BackgroundReport/messages";
import Footer from "./Footer";
import ReportCADisclosure from "../../../components/BackgroundReport/CADisclosure";
import ReportHeader from "../../../components/BackgroundReport/Header";
import ReportSummary from "../../../components/BackgroundReport/Summary";
import Modal from "../../../components/Modal/modal";
import Text from "../../../components/Text";
import teamMember from "../../../models/team-member";
import { CONSENT_FLOW_URL } from "../../../services/api/base-api";

const propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func
};

const CandidateReport = observer(
  class CandidateReport extends Component {
    constructor(props) {
      super(props);
      this.bottom = null;
      this.model = props.model;
      this.onUnload = this.onUnload.bind(this);
      this.redirect_url = localStorage.getItem("redirect_url");
      this.applicant_survey_seen = localStorage.getItem(
        "applicant_survey_seen"
      );
      this.teamModel = teamMember.retrieveMember();
      this.isTeamMember = teamMember.getPartnerId;
      this.showTypeform =
        undefined != process.env.REACT_APP_TYPEFORM_SHOW
          ? process.env.REACT_APP_TYPEFORM_SHOW.toLowerCase()
          : "true";
    }
    componentDidMount() {
      //  Only when the status is consent A popup need to be show on browser to be sure to exit or close the tab.
      if (!this.redirect_url && this.model.status === "consent") {
        window.addEventListener("beforeunload", this.onUnload);
      }

      if (
        !this.redirect_url &&
        this.applicant_survey_seen !== "true" &&
        !this.isTeamMember &&
        this.showTypeform === "true"
      ) {
        this.showSurvey();
      }
    }
    componentWillUnmount() {
      if (!this.redirect_url)
        window.removeEventListener("beforeunload", this.onUnload);
    }
    openUrl = () => {
      if (!this.model.read_only || !this.redirect_url) return;
      const timer = parseInt(env().REACT_APP_REDIRECT_URL_TIMER, 10);
      window.setTimeout(
        () => {
          window.location.assign(this.redirect_url);
          localStorage.removeItem("redirect_url");
        },
        !isNaN(timer) ? timer : 5000
      );
    };
    onUnload = e => {
      let confirmationMessage =
        "It looks like you have been editing something.";
      confirmationMessage +=
        "If you leave before saving, your changes will be lost.";
      (e || window.event).returnValue = confirmationMessage; // eslint-disable-line no-param-reassign
      return confirmationMessage;
    };
    printPage = () => {
      window.print();
    };

    showSurvey = () => {
      const { toggle } = createPopup(
        process.env.REACT_APP_TYPEFORM_APPLICANT_ID,
        { autoClose: true }
      );
      toggle();
      localStorage.setItem("applicant_survey_seen", "true");
    };

    isReview = () => {
      return (
        this.model.ssnReview || this.model.worker_status === "review__identity"
      );
    };

    redirectToDrugTestLandingPage = emailId => {
      if (!emailId) return;
      window.location.href = `${CONSENT_FLOW_URL}/consent/${emailId}/drugtesting`;
    };

    redirectToNewReviewIdentityLandingPage = emailId => {
      const queryParams = this.model.queryParams || "";
      window.location.href = `${CONSENT_FLOW_URL}/pii/${emailId}/mismatch${queryParams}`;
    };

    render() {
      const { isPDFReport } = this.props;
      const {
        loading,
        processing_checks,
        read_only,
        shouldScheduleDrugTest
      } = this.model;
      const emailId = this.model.candidateConsentEmailId;
      const paddingBottom = read_only ? 280 : 180;

      if (loading) return <Loader />;
      if (shouldScheduleDrugTest)
        return this.redirectToDrugTestLandingPage(emailId);
      if (processing_checks && this.isReview())
        return this.redirectToNewReviewIdentityLandingPage(emailId);

      if (processing_checks)
        return (
          <ReportsProcessing
            candidateConsentEmailId={emailId}
            isReview={this.model.ssnReview}
            processing={this.model.processing_checks}
            redirect_url={this.model.redirect_url}
            worker_status={this.model.worker_status}
          />
        );

      this.openUrl();
      return (
        <div style={{ ...styles.wrapper, paddingBottom }}>
          <Modal
            open={!this.model.review_modal_checked}
            title={
              <Text>
                <FormattedMessage {...messages.reviewModalTitle} />
              </Text>
            }
            primaryButtonProps={{
              title: "Ok",
              onClick: this.model.checkReviewModal
            }}
          >
            <Text align="left">
              <FormattedHTMLMessage {...messages.reviewModalContentPart1} />
              {this.model.partner}
              <FormattedHTMLMessage {...messages.reviewModalContentPart2} />
            </Text>
          </Modal>

          <ReportHeader isPDFReport={isPDFReport} />

          <div style={styles.reportContent}>
            <ReportCADisclosure model={this.model} />
            <ReportDate model={this.model} />
            <ApplicantInfo
              model={this.model}
              isPDFReport={isPDFReport}
              printPage={this.printPage}
            />
            <ReportSummary model={this.model} />
            <ReportChecks model={this.model} isPDFReport={isPDFReport} />
          </div>
          <Footer
            model={this.model}
            onSubmit={this.props.onSubmit}
            isPDFReport={isPDFReport}
          />
        </div>
      );
    }
  }
);

CandidateReport.propTypes = propTypes;

export default CandidateReport;
