/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { parseQuery } from "turn-shared";
import { turnAPI, ga } from "../../services";
import ErrorPage from "../ErrorPage";
import BGCLandingPage from "../BGCLandingPage";
import StatusErrors from "./status-errors";
import { TurnLoader } from "../../components/Loader/TurnLoader";
import BGCCanadaLandingPage from "../BGCCanadaLandingPage";
import env from "@mars/heroku-js-runtime-env";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import { CONSENT_FLOW_URL } from "../../services/api/base-api";

const PartnerSpecificBGC = observer(
  class PartnerSpecificBGC extends Component {
    static propTypes = {
      params: PropTypes.object,
      router: PropTypes.object
    };
    static defaultProps = {
      params: {}
    };
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        errorMessage: "",
        errors: false,
        check_id: "",
        recheck_id: null,
        email_id: "",
        country: "",
        fields: null,
        disclosures: null,
        partner_name: "",
        partner_package: "",
        one_off_checks: null
      };
    }
    componentDidMount() {
      ga.send(PageType.PartnerSpecificBGC, PageEvent.pageLoaded);
      this.apply();
    }

    isBetaConsentPartner = partner_id => {
      const allowedPartners = env().REACT_APP_BETA_CONSENT_PARTNERS || [];
      return allowedPartners.includes(partner_id);
    };

    redirectToNewConsentFlow = (
      emailId = "",
      partner_name = "",
      package_id = ""
    ) => {
      !!partner_name && !!package_id
        ? (window.location.href = `${CONSENT_FLOW_URL}/apply/${partner_name}/${package_id}`)
        : (window.location.href = `${CONSENT_FLOW_URL}/consent/${emailId}`);
    };

    apply = async () => {
      this.setState({ loading: true });
      let response;
      const { params } = this.props;

      const queryParams = parseQuery(this.props.router.location.search);

      // If we have a recheck_id, we'll set it in state
      if (queryParams.recheck_id) {
        this.setState({ recheck_id: queryParams.recheck_id });
      }

      if (params.partner_name && params.partner_id) {
        response = await turnAPI.apply({ ...params });
      } else if (params.email_id) {
        response = await turnAPI.getCandidateData(params.email_id);
      } else {
        this.handleStatusErrors(500);
        this.setState({ loading: false });
        return;
      }

      const { data } = response;
      switch (response.status) {
        case 200:
          // If back-end does not reply with an email_id, we'll switch to
          // E2E API with country disambiguation
          if (data.email_id) {
            this.setState({ email_id: data.email_id });
          } else if (data.country) {
            this.setState({ country: data.country });
          } else {
            this.handleStatusErrors(500);
          }
          if (params.email_id && data.partner_name && data.package_id) {
            this.setState({
              partner_name: data.partner_name,
              partner_package: data.package_id
            });
          } else {
            this.setState({
              partner_name: params.partner_name,
              partner_package: params.partner_id
            });
          }
          break;
        default:
          this.handleStatusErrors(response.status);
          break;
      }

      if (data.partner_id && this.isBetaConsentPartner(data.partner_id)) {
        const partner_name = params.partner_name;
        const package_id = params.partner_id;
        const email_id =
          this.state.email_id || data.email_id || params.email_id;

        if (email_id || (partner_name && package_id))
          this.redirectToNewConsentFlow(email_id, partner_name, package_id);
      }

      if (this.state.country == "CA") {
        const query = parseQuery(this.props.router.location.search);
        const package_id = this.state.partner_package;
        const requestBody = {
          package_id,
          zipcode: query.zipcode || "00000",
          partner: this.state.partner_name
        };

        const emailIdVerification = params.email_id
          ? await this.verifyUrl(params.email_id)
          : true;

        if (emailIdVerification) {
          const fcraResponse = await turnAPI
            .setToken(env().REACT_APP_TURN_TOKEN)
            .applyCanada(requestBody);

          switch (fcraResponse.status) {
            case 200:
            case 201:
              this.setState({
                check_id: fcraResponse.data.check_id,
                fields: fcraResponse.data.data,
                disclosures: fcraResponse.data.disclosures,
                one_off_checks: fcraResponse.data.custom_checks || []
              });
              break;
            default:
              this.handleStatusErrors(fcraResponse.status);
              break;
          }
        }
      }

      this.setState({ loading: false });
    };

    verifyUrl = async email_id => {
      const response = await turnAPI.checkCanadaEmailId(email_id);
      switch (response.status) {
        case 200:
          return true;
        default:
          this.handleStatusErrors(response.status);
          return false;
      }
    };

    handleStatusErrors = status => {
      this.setState({
        errorMessage: <StatusErrors status={status} />,
        errors: true
      });
    };

    render() {
      if (this.state.errors) {
        return <ErrorPage message={this.state.errorMessage} noLink />;
      }

      if (this.state.loading) {
        return <TurnLoader opacity={1} />;
      }

      if (this.state.country === "CA") {
        return (
          <BGCCanadaLandingPage
            checkId={this.state.check_id}
            fields={this.state.fields}
            disclosures={this.state.disclosures}
            oneOffChecks={this.state.one_off_checks}
            partner={this.state.partner_name}
            params={this.props.params}
            router={this.props.router}
          />
        );
      } else {
        return (
          <BGCLandingPage
            email_id={this.state.email_id}
            recheck_id={this.state.recheck_id}
            params={this.props.params}
            router={this.props.router}
          />
        );
      }
    }
  }
);

export default PartnerSpecificBGC;
